import React from "react";
import { Typography } from "@material-ui/core";

export const Score = ({ no, total }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "space-between",
        marginLeft: 20,
        marginRight: 20
      }}
    >
      <Typography variant="h6">Question {no}</Typography>
      <Typography variant="h6">
        Score: {no - 1} / {total}
      </Typography>
    </div>
  );
};
