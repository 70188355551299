import _ from "lodash";
import UIfx from "uifx";
import React, { useState, useMemo } from "react";
import {
  CardContent,
  Card,
  Typography,
  makeStyles,
  CardHeader,
  Button
} from "@material-ui/core";

import mp3Wrong from "../wrong.mp3";
import mp3Correct from "../correct.mp3";

import clsx from "clsx";
import { Letter } from "./Quizz";

const duration = 500;
const durationInSeconds = duration / 1000;

const useStyles = makeStyles(theme => ({
  root: {
    width: 375,
    maxWidth: "100%",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderRadius: 0
  },
  wrongAnswerTrue: {
    backgroundColor: "rgba(255,0,0, 0.5) !important",
    transition: `background-color ${durationInSeconds}s`
  },
  wrongAnswerFalse: {
    backgroundColor: "transparent !important",
    transition: `background-color ${durationInSeconds}s`
  },
  rightAnswerTrue: {
    backgroundColor: "rgba(0,128,0, 0.5) !important",
    transition: `background-color ${durationInSeconds}s`
  },
  rightAnswerFalse: {
    backgroundColor: "transparent !important",
    transition: `background-color ${durationInSeconds}s`
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  },
  buttonRoot: {
    margin: 10,
    textTransform: "none",
    border: "1px solid red",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main
  },
  buttonLabel: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",
    "& > *:first-child": {
      paddingRight: 10
    }
  }
}));

const beepWrong = new UIfx(mp3Wrong);
const beepCorrect = new UIfx(mp3Correct);

export const Question = ({ question, onSuccess, onError }) => {
  const initialWrongAnswer = question.answers.map(() => false);
  const [wrongAnswer, setWrongAnswer] = useState(initialWrongAnswer);
  const [rightAnswer, setRightAnswer] = useState(false);

  const answers = useMemo(() => _.shuffle(question.answers), [
    question.answers
  ]);

  const classes = useStyles();

  const letter = {
    position: "fixed",
    right: 20,
    top: 100,
    animation: `winLetter ${durationInSeconds * 2}s`
  };

  const handleClick = (value, index) => {
    if (value !== question.correctAnswer) {
      beepWrong.play();
      onError();
      setWrongAnswer(prev => {
        const next = [...prev];
        next[index] = true;
        return next;
      });
      setTimeout(
        () =>
          setWrongAnswer(prev => {
            const next = [...prev];
            next[index] = false;
            return next;
          }),
        duration
      );
    } else {
      beepCorrect.play();
      setWrongAnswer(initialWrongAnswer);
      setRightAnswer(true);

      setTimeout(() => {
        setRightAnswer(false);
        setTimeout(() => {
          onSuccess();
        }, duration);
      }, duration);
    }
  };

  return (
    <Card classes={{ root: classes.root }} elevation={0}>
      <CardHeader
        title={question.text}
        titleTypographyProps={{
          variant: "h5"
        }}
      ></CardHeader>
      <CardContent style={{ padding: 0 }}>
        {rightAnswer && (
          <div style={letter}>
            <Letter id={question.id} value={question.correctAnswer} />
          </div>
        )}
        <div className={classes.buttons}>
          {answers.map(
            (answer, i) =>
              !console.log(wrongAnswer[i]) && (
                <Button
                  classes={{
                    root: classes.buttonRoot,
                    label: classes.buttonLabel
                  }}
                  variant="outlined"
                  onClick={() => handleClick(answer.value, i)}
                  className={clsx({
                    [classes.wrongAnswerTrue]: wrongAnswer[i],
                    [classes.wrongAnswerFalse]: !wrongAnswer[i],
                    [classes.rightAnswerTrue]:
                      answer.value === question.correctAnswer && rightAnswer,
                    [classes.rightAnswerFalse]:
                      answer.value === question.correctAnswer && !rightAnswer
                  })}
                >
                  <Typography variant="h5">{answer.text}</Typography>
                </Button>
              )
          )}
        </div>
      </CardContent>
    </Card>
  );
};
