export const allQuestions = [
  {
    id: 1,
    text: `Quelle est la 2e ville la plus peuplée du Canada ?`,
    correctAnswer: "V",
    answers: [
      { text: `Toronto`, value: "" },
      { text: `Montréal`, value: "V" },
      { text: `Calgary`, value: "" }
    ]
  },
  {
    id: 2,
    text: `Qui a vaincu le Roi de la Nuit ?`,
    correctAnswer: "e",
    answers: [
      { text: `Arya Stark`, value: "e" },
      { text: `Jon Snow`, value: "" },
      { text: `Daenerys Targaryen`, value: "" }
    ]
  },
  {
    id: 3,
    text: `Quel acteur connu pour ses films d'action se nomme Van Varenberg ?`,
    correctAnswer: "u",
    answers: [
      { text: `Sylvester Stalonne`, value: "" },
      { text: `Arnold Schwarzennegger`, value: "" },
      { text: `Jean-Claude Van Damme`, value: "u" }
    ]
  },
  {
    id: 4,
    text: `Dans quel pays paye-t-on avec des Baths ?`,
    correctAnswer: "x",
    answers: [
      { text: `Vietnam`, value: "" },
      { text: `Thailand`, value: "x" },
      { text: `Cambodge`, value: "" }
    ]
  },
  {
    id: 5,
    text: `Quel pays produit le plus de films ?`,
    correctAnswer: "t",
    answers: [
      { text: `USA`, value: "" },
      { text: `Nigéria`, value: "" },
      { text: `Inde`, value: "t" }
    ]
  },
  {
    id: 6,
    text: `Quel est le nom du frère de Son Gokû ?`,
    correctAnswer: "u",
    answers: [
      { text: `Raditz`, value: "u" },
      { text: `Son Gohan`, value: "" },
      { text: `Bardack`, value: "" }
    ]
  },
  {
    id: 7,
    text: `Qui est surnommé Heisenberg ?`,
    correctAnswer: "ê",
    answers: [
      { text: `Jesse Pinkman`, value: "" },
      { text: `Walter White`, value: "ê" },
      { text: `Gus Fring`, value: "" }
    ]
  },
  {
    id: 8,
    text: `Quelle série retrace l'histoire du plus célèbre et du plus violent Narco-trafiquant de l'histoire ?`,
    correctAnswer: "t",
    answers: [
      { text: `Narcos`, value: "t" },
      { text: `Breaking bad`, value: "" },
      { text: `El Chapo`, value: "" }
    ]
  },
  {
    id: 9,
    text: `Comment s'appelle le plus célèbre plombier italien ?`,
    correctAnswer: "r",
    answers: [
      { text: `Dario`, value: "" },
      { text: `Marco`, value: "" },
      { text: `Mario`, value: "r" }
    ]
  },
  {
    id: 10,
    text: `Quel jeu ne fait pas parti de la saga Zelda ?`,
    correctAnswer: "e",
    answers: [
      { text: `Ocarina of Time`, value: "" },
      { text: `A Link to the future`, value: "e" },
      { text: `Breath of wild`, value: "" }
    ]
  },
  {
    id: 11,
    text: `Quelle est la plus vielle équipe de hockey sur glace au monde toujours en activité ?`,
    correctAnswer: "m",
    answers: [
      { text: `Club de Hockey Canadiens`, value: "m" },
      { text: `Maple leafs`, value: "" },
      { text: `Les Sénateurs`, value: "" }
    ]
  },
  {
    id: 12,
    text: `En quelle année fut créée la société Nintendo ?`,
    correctAnswer: "o",
    answers: [
      { text: `1889`, value: "o" },
      { text: `1970`, value: "" },
      { text: `1989`, value: "" }
    ]
  },
  {
    id: 13,
    text: `Comment s'appelle le créateur de Mario, Zelda et Donkey Kong ?`,
    correctAnswer: "n",
    answers: [
      { text: `Kenichiro Yoshida`, value: "" },
      { text: `Shigeru Miyamoto`, value: "n" },
      { text: `Hirokasu Yasuhara`, value: "" }
    ]
  },
  {
    id: 14,
    text: `Quel personnage bleu a un fidèle ami qui s'appelle Knuckles ?`,
    correctAnswer: "p",
    answers: [
      { text: `Sonic`, value: "p" },
      { text: `Carapuce`, value: "" },
      { text: `Mega Man`, value: "" }
    ]
  },
  {
    id: 15,
    text: `Quel jeu vidéo a pour nom original  "Winning Eleven" ?`,
    correctAnswer: "a",
    answers: [
      { text: `Fifa`, value: "" },
      { text: `Pro Evolution Soccer`, value: "a" },
      { text: `Football manager`, value: "" }
    ]
  },
  {
    id: 16,
    text: `Qui peut tuer des monstres en un seul coup de poing ?`,
    correctAnswer: "r",
    answers: [
      { text: `Naruto`, value: "" },
      { text: `Ken le survivant`, value: "" },
      { text: `Saitama`, value: "r" }
    ]
  },
  {
    id: 17,
    text: `De qui la multinationale Vought International gère-t-elle les droits ?`,
    correctAnswer: "r",
    answers: [
      { text: `Les héros Marvel`, value: "" },
      { text: `Les Sept`, value: "r" },
      { text: `DC Comics`, value: "" }
    ]
  },
  {
    id: 18,
    text: `Lequel de ces coups n'est pas un spécial de Dhalsim ? `,
    correctAnswer: "a",
    answers: [
      { text: `Yoga fire`, value: "" },
      { text: `Yoga burn`, value: "a" },
      { text: `Yoga flame`, value: "" }
    ]
  },
  {
    id: 19,
    text: `Quel jeu pokémon n'existe pas ?`,
    correctAnswer: "i",
    answers: [
      { text: `Pokémon jaune`, value: "" },
      { text: `Pokémon orange`, value: "i" },
      { text: `Pokémon rouge`, value: "" }
    ]
  },
  {
    id: 20,
    text: `Quel est le jeu vidéo le plus vendu de tous les temps ?`,
    correctAnswer: "n",
    answers: [
      { text: `Minecraft`, value: "n" },
      { text: `Tetris`, value: "" },
      { text: `GTA V`, value: "" }
    ]
  }
];
