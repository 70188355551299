import React, { useState } from "react";
import UIfx from "uifx";
import "./App.css";
import { Button, useTheme } from "@material-ui/core";
import { Quizz } from "./components/Quizz";
import mp3Start from "./start.mp3";

const beepStart = new UIfx(mp3Start);

const App = () => {
  const [isStarted, setIsStarted] = useState(false);

  const theme = useTheme();

  const start = () => {
    beepStart.play();
    setTimeout(() => setIsStarted(true), 500);
  };

  return (
    <div className="App" style={{ textAlign: "center" }}>
      <div
        className="App-header"
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "calc(10px + 2vmin)",
          background: theme.palette.background.default,
          color: theme.palette.text.primary
        }}
      >
        {!isStarted ? (
          <>
            <h1>
              Bienvenue au <nobr>Geek 'wizz</nobr>
            </h1>
            <h3> Le quizz pour les geeks! </h3> <h3> </h3>
            <Button variant="outlined" color="primary" onClick={start}>
              Démarrer le quizz{" "}
            </Button>{" "}
          </>
        ) : (
          <Quizz />
        )}
      </div>
    </div>
  );
};

export default App;
