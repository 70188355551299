import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import { cyan, blueGrey } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    background: {
      default: blueGrey[900]
    },
    primary: {
      main: cyan[500],
      contrastText: blueGrey[50]
    },
    text: {
      primary: blueGrey[50],
      secondary: blueGrey[900]
    }
  },
  status: {
    danger: "orange"
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
