import _ from "lodash";
import UIfx from "uifx";
import React, { useState, useEffect } from "react";
import { allQuestions } from "./allQuestions";
import { Question } from "./Question";
import { Score } from "./Score";
import { Typography, Button, useTheme, makeStyles } from "@material-ui/core";
import kenawen from "../kenawen.jpg";
import mp3Shuffle from "../shuffle.mp3";
import mp3Win from "../win.mp3";
import clsx from "clsx";

const beepShuffle = new UIfx(mp3Shuffle);
const beepWin = new UIfx(mp3Win);

const start = 0;

export const Quizz = () => {
  const [questions] = useState(_.shuffle(allQuestions));
  const [errorCount, setErrorCount] = useState(0);

  const [current, setCurrent] = useState(start);
  const handleSuccess = () => setCurrent(prev => prev + 1);

  useEffect(() => {
    if (current === questions.length) {
      beepWin.play();
    }
  }, [current, questions.length]);

  return current === questions.length ? (
    <Result errorCount={errorCount} />
  ) : (
    <>
      <Score key="score" no={current + 1} total={questions.length} />
      <Question
        key="question"
        no={current + 1}
        question={questions[current]}
        onSuccess={handleSuccess}
        onError={() => setErrorCount(prev => prev + 1)}
      />
    </>
  );
};

const allLetters = allQuestions.map(q => ({
  id: q.id,
  value: q.correctAnswer
}));

const useStyles = makeStyles(theme => ({
  sorted: {
    maxWidth: 375,
    width: "100%",
    opacity: 0,
    position: "absolute",
    top: 10000
  },
  fadeIn: {
    opacity: 1,
    position: "relative",
    top: 0,
    transition: "opacity 3s"
  },
  notSorted: {
    maxWidth: 375,
    width: "100%",
    opacity: 1
  },
  fadeOut: {
    opacity: 0,
    transition: "opacity 1s"
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(8, 1fr)",
    gridTemplateRows: "repeat(3, 50px)",
    // justifyContent: "space-evenly",
    width: "calc(100% - 20px)",
    margin: 10
  }
}));
export const Result = ({ errorCount }) => {
  const [sortedBegin, setSortedBegin] = useState(false);
  const [sortedEnd, setSortedEnd] = useState(false);
  const [letters, setLetters] = useState(_.shuffle(allLetters));
  const classes = useStyles();

  const letterFor = arr =>
    arr.map(x => <Letter key={x.id} value={x.value} id={x.id} />);

  const sort = () => {
    beepShuffle.play();
    setSortedBegin(true);

    setTimeout(() => {
      setSortedEnd(true);

      setLetters(_.sortBy(allLetters, "id"));
    }, 1500);
  };

  const renderLetters = () => (
    <div key={Math.random()} className={classes.grid}>
      {letterFor(letters)}
      <Letter
        key="-"
        value="-"
        style={{ gridColumnStart: 5, gridRowStart: 1 }}
      />
      <Letter
        key=" "
        value=" "
        style={{ gridColumnStart: 8, gridRowStart: 1 }}
      />
      <Letter
        key=" "
        value=" "
        style={{ gridColumnStart: 5, gridRowStart: 2 }}
      />
      <Letter
        key="?"
        value="?"
        style={{ gridColumnStart: 8, gridRowStart: 3 }}
      />
    </div>
  );

  return (
    <>
      <div
        className={clsx({
          [classes.sorted]: true,
          [classes.fadeIn]: sortedEnd
        })}
      >
        <h1>Vinod, </h1>
        {renderLetters()}
        <img src={kenawen} alt="Ken-Awen" />
        {errorCount > 0 && <p>PS: Tu as fait {errorCount} erreurs ;-)</p>}
      </div>

      <div
        className={clsx({
          [classes.notSorted]: true,
          [classes.fadeOut]: sortedBegin
        })}
      >
        <h1 key="h1">Bravo !!</h1>
        <h1 key="h1.2">Peux-tu deviner la dernière question ?</h1>

        {renderLetters()}
        <p> </p>
        <Button key="sort" color="primary" variant="outlined" onClick={sort}>
          {" "}
          Mettre les lettres dans l'ordre
        </Button>
      </div>
    </>
  );
};

export const Letter = ({ id, value, style }) => {
  const theme = useTheme();

  const newStyle = _.merge(
    {
      width: 40,
      height: 40,
      // margin: 5,
      border: "1px solid red",
      borderColor: id != null ? theme.palette.text.primary : "transparent",
      backgroundColor: "transparent",
      color: theme.palette.text.primary,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    style
  );

  return (
    <div style={newStyle}>
      <Typography variant="h5">{value}</Typography>
    </div>
  );
};
